@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';

html,
body {
  p-inputmask {
    width: 100%;
    .p-inputtext {
      @extend .ni-input-rb;
      @extend .p-xs_sm;
      border-radius: $border-radius-super-curvy;
    }
  }
}
