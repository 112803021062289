@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';
@import '../../styles/abstracts/mixins.scss';

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.p-dialog {
  max-width: unset;
  justify-content: center;
  animation: slideUp 0.3s;

  @include on-desktop {
    max-width: var(--ni-modal-desktop-width);
    max-height: calc(100% - 96px);
    border-radius: $border-radius-super-curvy-m;
    margin: 0 var(--ni-4xl-spacing);
  }
}

html,
body {
  &.freeze {
    .p-dialog {
      pointer-events: none;
    }
  }

  .p-dialog-bottom {
    .p-dialog {
      margin: 0;
      width: 100%;
      box-shadow: none;
      max-height: calc(100% - 40px);
    }
  }

  .p-dialog {
    background-color: white;
    border-top-right-radius: $border-radius-super-curvy-m;
    border-top-left-radius: $border-radius-super-curvy-m;

    @include on-desktop {
      border-radius: $border-radius-super-curvy-m;
    }

    .p-dialog-header {
      position: sticky;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      padding: var(--ni-xl-spacing) var(--ni-lg-spacing) var(--ni-sm-spacing);
      border-radius: $border-radius-super-curvy-m $border-radius-super-curvy-m 0 0;
      color: var(--black);

      @include on-desktop {
        padding: var(--ni-2xl-spacing) var(--ni-2xl-spacing) var(--ni-lg-spacing) var(--ni-2xl-spacing);
        max-width: var(--ni-modal-desktop-width);
      }

      .p-dialog-title {
        @extend .p-lg-bold;
        color: var(--black);
        font-weight: bold;
      }

      .p-dialog-header-icon {
        margin-left: var(--ni-lg-spacing);
        width: auto;

        .pi {
          font-weight: bold;
        }

        &:enabled:hover {
          background: unset;
        }

        &:focus {
          box-shadow: unset;
        }
      }
    }

    &.ni-dialog-no-header {
      .p-dialog-content {
        &:last-of-type {
          border-radius: $border-radius-super-curvy-m $border-radius-super-curvy-m 0 0;
          @include on-desktop {
            border-radius: $border-radius-super-curvy-m;
          }
        }
      }
    }

    .p-dialog-content {
      padding: 0;
      border-radius: 0;
      overflow: auto;
      width: 100%;
      color: var(--black);

      @include on-desktop {
        max-width: var(--ni-modal-desktop-width);
      }

      &:last-of-type {
        @include on-desktop {
          border-radius: 0 0 $border-radius-super-curvy-m $border-radius-super-curvy-m;
        }
      }
    }

    .ni-dialog-title {
      width: 100%;
      padding: var(--ni-xl-spacing) var(--ni-lg-spacing) 0 var(--ni-lg-spacing);

      @include on-desktop {
        padding: var(--ni-2xl-spacing) var(--ni-2xl-spacing) 0 var(--ni-2xl-spacing);
      }

      &.wide-padding {
        @include on-desktop {
          padding: var(--ni-2xl-spacing) var(--ni-modal-horizontal-spacing) 0 var(--ni-modal-horizontal-spacing);
        }
      }

      &.center {
        text-align: center;
      }
    }

    .ni-dialog-content {
      width: 100%;
      padding: 0 var(--ni-lg-spacing) var(--ni-xl-spacing);

      @include on-desktop {
        padding: 0 var(--ni-2xl-spacing) var(--ni-2xl-spacing) var(--ni-2xl-spacing);
      }

      &.center {
        text-align: center;
      }

      &.wide-padding {
        @include on-desktop {
          padding: var(--ni-2xl-spacing) var(--ni-modal-horizontal-spacing);
        }
      }
    }

    .ni-dialog-footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 var(--ni-lg-spacing) var(--ni-xl-spacing);

      @include on-desktop {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0 var(--ni-2xl-spacing) var(--ni-2xl-spacing);
      }

      &.sticky {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        padding: var(--ni-lg-spacing) var(--ni-lg-spacing) var(--ni-xl-spacing);
        box-shadow: 0 1px 4px 0 var(--gray-50);

        @include on-desktop {
          padding: 0 var(--ni-2xl-spacing) var(--ni-2xl-spacing) var(--ni-2xl-spacing);
          box-shadow: none;
          border-radius: 0 0 $border-radius-super-curvy-m $border-radius-super-curvy-m;
        }
      }

      ni-button-rb:not(:first-child) {
        margin-right: var(--ni-xl-spacing);
        margin-top: var(--ni-lg-spacing);

        @include on-desktop {
          margin-top: unset;
        }
      }

      ni-button-rb {
        width: 100%;
      }

      &.flex-start {
        @include on-desktop {
          justify-content: flex-start;

          > ni-button-rb {
            max-width: fit-content;
          }
        }
      }

      &.flex-end {
        @include on-desktop {
          justify-content: flex-end;

          > ni-button-rb {
            max-width: fit-content;
          }
        }
      }

      &.space-between {
        @include on-desktop {
          justify-content: space-between;

          > ni-button-rb {
            max-width: fit-content;
          }
        }
      }

      &.wide-padding {
        @include on-desktop {
          padding-right: var(--ni-modal-horizontal-spacing);
          padding-left: var(--ni-modal-horizontal-spacing);
        }
      }

      &.with-border {
        border-top: 1px solid var(--gray-50);
        padding: var(--ni-lg-spacing) var(--ni-lg-spacing) var(--ni-xl-spacing);

        @include on-desktop {
          padding: var(--ni-xl-spacing) var(--ni-2xl-spacing) var(--ni-2xl-spacing);
        }
      }
    }
  }
}
