@import '@next-insurance/ni-material/styles/mixins.scss';

.grid-container {
  @include on-desktop {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    gap: var(--ni-xl-spacing);
  }
}

.grid-form-container {
  grid-column: 3 / 10;
  max-width: 764px;
}
