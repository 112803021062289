@font-face {
  font-family: OpenSans;
  src: url('/assets/fonts/Open_Sans/OpenSans-Regular.ttf?corsbuster=true') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  src: url('/assets/fonts/Open_Sans/OpenSans-Bold.ttf?corsbuster=true') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  font-weight: 300;
  src: url('/assets/fonts/Open_Sans/OpenSans-Light.ttf?corsbuster=true') format('truetype');
  font-display: swap;
}
