@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';
@import '../../styles/abstracts/mixins.scss';

.ni-overlay-panel {
  &.full-screen {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    background-color: $blue-ink-70-perc;

    .p-overlaypanel-content {
      height: 100%;
    }
  }
}
