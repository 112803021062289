@use 'sass:map';

// Variables
@import 'variables.scss';

// Configurations

// $fix-mqs
//
// Define width for browsers w/out media query support
//
// $fix-mqs - {boolean}
// @source  - http://jakearchibald.github.com/sass-ie/

$fix-mqs: false !default;

// $old-ie
//
// Turn on/off IE specific styles
//
// $old-ie - {boolean}
// @source  - http://jakearchibald.github.com/sass-ie/

$old-ie: false !default;
$border-radius: 4px;
$desktop-limit-wide: 556px;

// $breakpoints
//
// Define major breakpoints
// There are no common breakpoints; examples based on the official Sass styleguide
// You'll need to define your own breakpoints to suit your design
//
// $breakpoints - {map}

$breakpoints: (
  xsmall: null,
  small: 480,
  medium: 768,
  large: 1024,
  xlarge: 1280,
);

// $zindex
//
// Define z-indexes for various elements
//
// $zindex - {map}
// @source - http://www.sitepoint.com/using-sass-maps/

$zindex: (
  overlay: 9000,
  dropdown: 8000,
  nav: 7000,
  header: 6000,
  footer: 5000,
  pseduo: 4000,
  default: 1,
  bottomless-pit: -10000,
);

@mixin hover {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

@mixin on-desktop {
  @include mq('768px') {
    @content;
  }
}

@mixin on-wide-desktop {
  @include mq('1024px') {
    @content;
  }
}

@mixin on-ios {
  .iosbrowser {
    @content;
  }
}

@mixin mq($breakpoint, $query: 'min-width', $type: 'screen') {
  @if $fix-mqs {
    @if $fix-mqs >= $breakpoint {
      @content;
    }
  } @else {
    @media #{$type} and (#{$query}: #{$breakpoint}) {
      @content;
    }
  }
}

@mixin font($size, $color: $blue-ink) {
  font-size: $size;
  @if $color {
    color: $color;
  }
}

@mixin default-container() {
  display: block;
  max-width: 360px;
  margin: 0 auto;
}

// For the arrow in the profile component
@mixin supported($feature) {
  .#{$feature} & {
    @content;
  }
}

@mixin not-supported($feature) {
  .no-js &,
  .no-#{$feature} & {
    @content;
  }
}

@mixin mask($url, $size: contain, $color: $dodgerblue, $repeat: no-repeat, $x: 50%, $y: 50%) {
  background: $color;
  mask: url($url) $repeat $x $y;
  mask-size: $size;
}

@mixin rotate($degrees: 0, $scale: 1) {
  transform: rotate($degrees) scale($scale);
}

@mixin arrow($direction: right, $scale: 1, $color: $dodgerblue) {
  $rotate: map.get(
    (
      right: 90deg,
      down: 180deg,
      left: -90deg,
      up: 0deg,
    ),
    $direction
  );

  @include mask(
    'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMXB4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDExIDciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI4MS4wMDAwMDAsIC0xODMuMDAwMDAwKSIgZmlsbD0iIzYxNjY2RCI+ICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTUuMDAwMDAwLCAxNDguMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnPiAgICAgICAgICAgICAgICAgICAgPGc+ICAgICAgICAgICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjY1LjAwMDAwMCwgMzQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuOTE0NzY1LCA1Ljg1OTMyMikgcm90YXRlKDEzNC4wMDAwMDApIHRyYW5zbGF0ZSgtNS45MTQ3NjUsIC01Ljg1OTMyMikgdHJhbnNsYXRlKDEuOTE0NzY1LCAxLjg1OTMyMikiPiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy42OTMzOTgsIDYuMDUzNDE2KSByb3RhdGUoOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNjkzMzk4LCAtNi4wNTM0MTYpICIgeD0iMi42OTMzOTgiIHk9IjIuNTUzNDE2MyIgd2lkdGg9IjIiIGhlaWdodD0iNyI+PC9yZWN0PiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgeD0iMC4xOTMzOTgwMDMiIHk9IjAuMDUzNDE2Mjk1NCIgd2lkdGg9IjIiIGhlaWdodD0iNyI+PC9yZWN0PiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+',
    no-repeat,
    $color
  );
  @include rotate($rotate, $scale);

  transition: all 0.25s ease-in-out;
}

@mixin ni-radio-input($radio-unchecked-icon-path, $radio-checked-icon-path, $side-margin) {
  input[type='radio'] {
    display: none;

    &.ng-invalid.ng-touched + span {
      transition: background-color 1.5s ease-out;
      background-color: $warning-red;
      color: $blue-ink-80-perc;
      border-color: $red;
    }

    & + span {
      display: inline-block;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 40px;
      position: relative;
      font-size: $small-font-size;
      font-weight: $semi-bold-font-weight;
      width: 83px;
      margin: 5px $side-margin 5px 0;
      text-align: left;
      background: white;
      cursor: pointer;
      border-radius: 4px;
      color: $blue-default;
      border: solid 1px $gray-light-shade;

      &::after,
      &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
      }

      &:focus {
        color: $blue-default;
        border: solid 1px $blue-default;
        outline: none;
      }

      &::before {
        background: transparent url($radio-unchecked-icon-path) round;
      }

      &:hover {
        color: $blue-default;
        border: solid 1px $blue-default;
      }

      &.wide-radio {
        width: 120px;
        @include on-desktop {
          width: 140px;
        }
      }

      &.full-width {
        width: 100%;
      }
    }
  }

  input:checked[type='radio'] + span {
    background-color: $light-blue;
    border: solid 1px $blue-default;

    &::before {
      background: transparent url($radio-checked-icon-path) round;
    }
  }
}

@mixin ni-scroller {
  &::-webkit-scrollbar {
    appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 9px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $gray-light-shade;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray-light;
    border-radius: 8px;
  }
}

@mixin icons($path, $class-prefix, $icons) {
  @each $icon in $icons {
    &.#{$class-prefix}#{$icon} {
      background-image: url('#{$path}#{$icon}.svg');
    }
  }
}

@mixin background-gradient($color1, $color2) {
  background-image: linear-gradient(90deg, $color1 0, $color2 40px, $color1 80px);
  background-size: 600px;
}

@mixin status-badge($type) {
  text-transform: uppercase;
  border-radius: 2px;
  padding: 2px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;

  @if $type == red {
    background-color: $red-background;
    color: $red-text;
  }
  @if $type == grey {
    background-color: $gray-background;
    color: $grey-text;
  }
  @if $type == brown {
    background-color: $brown-background;
    color: $brown-text;
  }
  @if $type == green {
    background-color: $green-background;
    color: $green-text;
  }
  @if $type == blue {
    background-color: $blue-background;
    color: $blue-text;
  }
  @if $type == purple {
    background-color: $purple-background;
    color: $purple-text;
  }
}
