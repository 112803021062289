@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';

html,
body {
  .p-autocomplete {
    width: 100%;

    .p-inputtext {
      @extend .ni-input-rb;
      @extend .p-xs_sm;
      border-radius: $border-radius-super-curvy;
    }

    .p-autocomplete-panel {
      border-radius: $border-radius-super-curvy;
      box-shadow:
        rgba(0, 0, 0, 5%) 0 6px 24px 0,
        rgba(0, 0, 0, 8%) 0 0 0 1px;
      width: 100%;
      top: 60px !important;
      z-index: 10000;

      .p-autocomplete-items {
        padding: unset;

        .p-autocomplete-item.p-highlight {
          background-color: var(--gray-20);
        }
      }

      .p-autocomplete-item {
        @extend .p-xs_sm;
        color: var(--gray-75);
        display: flex;
        flex-direction: column;
        padding: var(--ni-md-spacing) var(--ni-lg-spacing);
        line-height: var(--ni-lg-spacing);
        border-top: none;
      }
    }
  }
}
