@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';
@import '../../styles/abstracts/mixins.scss';
html,
body {
  .p-tooltip {
    @include on-desktop {
      max-width: $desktop-limit-wide;
    }
    .p-tooltip-text {
      padding: var(--ni-md-spacing);
      border-radius: $border-radius-curvy-m;
      background-color: var(--gray-200);
      border: var(--gray-300) solid 2px;
      color: var(--black);
      box-shadow: none;
      font: $font-family-graphik;

      @include on-desktop {
        line-height: 150%;
      }
    }

    .p-tooltip-arrow {
      display: none;
    }
  }
}
