@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';

html,
body {
  p-toast {
    z-index: 2001;
  }

  .p-toast {
    opacity: 1;
    padding: 0 var(--ni-2xl-spacing);

    .p-toast-message {
      @extend .p-sm;

      &.p-toast-message {
        color: var(--black);

        .p-toast-message-content {
          .p-toast-summary {
            @extend .p-md-bold;
          }

          .p-toast-detail {
            margin-top: 0;
          }

          .p-toast-message-text {
            flex: 1;
          }

          element.style {
          }

          .p-icon {
            display: none;
          }

          .p-toast-message-icon {
            margin-top: var(--ni-xs-spacing);
            width: 30px;
            height: 20px;
            background-repeat: no-repeat;

            &::before {
              content: '';
            }
          }
        }
      }

      &.p-toast-message-error {
        background-color: var(--rose-10);
        border-color: var(--next-pink);

        .p-toast-message-content {
          .p-toast-summary {
            color: var(--magenta);
          }

          .p-icon {
            display: none;
          }

          .p-toast-message-icon {
            background-image: url('/assets/img/toast/error.png');
          }
        }
      }

      &.p-toast-message-success {
        background-color: var(--jade-20);
        border-color: var(--jade);

        .p-toast-message-content {
          .p-toast-summary {
            color: var(--pine-green);
          }

          .p-icon {
            display: none;
          }

          .p-toast-message-icon {
            background-image: url('/assets/img/toast/success.png');
          }
        }
      }

      &.p-toast-message-info {
        background-color: var(--sky-10);
        border-color: var(--next-blue);

        .p-toast-message-content {
          .p-toast-summary {
            color: var(--black);
          }

          .p-icon {
            display: none;
          }

          .p-toast-message-icon {
            background-image: url('/assets/img/toast/info.png');
          }
        }
      }

      &.p-toast-message-warning {
        background-color: var(--cream);
        border: solid var(--crimson-red);
        border-width: 0 0 0 6px;
        border-color: var(--next-orange);

        .p-toast-message-content {
          .p-toast-summary {
            color: var(--rust-orange);
          }

          .p-icon {
            display: none;
          }

          .p-toast-message-icon {
            background-image: url('/assets/img/toast/warning.png');
          }
        }
      }
    }
  }
}
