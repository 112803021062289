@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/next-icon-font.scss';
@import '../../styles/abstracts/mixins.scss';

html,
body {
  p-dropdown {
    .p-dropdown {
      background: var(--white);
      border: 1px solid var(--gray-50);
      border-radius: $border-radius-super-curvy;
      height: $ni-3xl-line-height;
      width: 100%;
      align-items: center;

      @include on-desktop {
        height: $input-height-desktop;
      }

      &:focus {
        border-color: var(--ocean-blue);
      }

      &:hover {
        border-color: var(--ocean-blue);
      }

      &:active {
        border-color: var(--ocean-blue);
      }

      &:focus-within {
        border-color: var(--ocean-blue);
        box-shadow: 0 0 1px 0 var(--ocean-blue);
      }

      &.p-disabled {
        border-color: var(--gray-50);
        background: var(--gray-20);

        &:hover {
          border-color: var(--gray-50);
        }

        .p-dropdown-trigger {
          .p-dropdown-trigger-icon {
            color: var(--gray-50);
          }
        }

        .p-dropdown-label {
          color: var(--gray-80);

          &.p-placeholder {
            color: var(--gray-80);
          }
        }
      }

      .p-dropdown-label {
        font-family: $font-family-graphik;
        font-size: $ni-sm-font-size;
        color: var(--black);
        padding: var(--ni-md-spacing);

        @include on-desktop {
          font-size: $ni-md-font-size;
        }

        &.p-placeholder {
          color: var(--gray-50);
        }
      }

      .p-dropdown-panel {
        border-color: var(--gray-50);
        border-radius: $border-radius-super-curvy;
        font-family: $font-family-graphik;
        margin-top: var(--ni-sm-spacing);

        .p-dropdown-filter-container {
          input {
            border-radius: $border-radius-super-curvy;
          }
        }

        .p-dropdown-items {
          padding: 0;

          .p-dropdown-item {
            padding: 12px 16px;
            line-height: $ni-xl-line-height;
            color: var(--black);
            white-space: normal;

            &:not(.p-disabled):hover {
              background-color: var(--gray-20);
            }
          }

          p-dropdownitem:first-of-type {
            .p-dropdown-item {
              border-top-left-radius: $border-radius-super-curvy;
              border-top-right-radius: $border-radius-super-curvy;
            }
          }

          p-dropdownitem:last-of-type {
            .p-dropdown-item {
              border-bottom-left-radius: $border-radius-super-curvy;
              border-bottom-right-radius: $border-radius-super-curvy;
            }
          }
        }
      }

      .p-dropdown-trigger {
        margin-right: var(--ni-lg-spacing);
        padding: 0;

        .p-icon-wrapper {
          width: auto;
          height: auto;
          border: none;
          @include icon-font-family();
          @include chevron-down();

          .p-dropdown-trigger-icon {
            display: none;
          }
        }
      }

      &.p-dropdown-open {
        .p-dropdown-trigger {
          .p-icon-wrapper {
            @include chevron-up();
          }
        }
      }
    }

    &.ng-invalid {
      &.ng-touched {
        .p-dropdown {
          border: 1px solid var(--rose);

          &:focus-within {
            border-color: var(--rose);
            box-shadow: 0 0 1px 1px var(--rose);
          }
        }
      }
    }
  }
}
