@import '../src/styles/abstracts/variables.scss';
@import '@next-insurance/ni-material/styles/typography-rebrand.scss';
@import '@next-insurance/ni-material/styles/mixins.scss';
@import 'primeicons/primeicons.css';
@import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import '@next-insurance/ni-material/styles/colors.scss';
@import '@next-insurance/ni-material/styles/typography-variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/variables-rebrand.scss';
@import '@next-insurance/ni-material/styles/fonts-rebrand.scss';
@import '@next-insurance/ni-material/styles/spacing.scss';
@import '@next-insurance/ni-material/styles/ni-input.scss';
@import '@next-insurance/ni-material/styles/ni-tooltip.scss';
@import '@next-insurance/ni-material/styles/status-label.scss';
@import '@next-insurance/ni-material/styles/date-picker.scss';
@import '@next-insurance/ni-material/styles/ni-dropdown.scss';

html,
body {
  margin: 0;
  font:
    17px OpenSans,
    sans-serif;
}

body {
  height: 100vh;
  min-height: 100vh;
  overflow-x: hidden;

  &.is-webview {
    padding-top: 0;
  }
}

* {
  box-sizing: border-box;
}

body:not(.is-webview) {
  ni-portal-root {
    padding-top: $nav-height-mobile;
    @include on-wide-desktop {
      padding-top: $nav-height-desktop;
    }
  }
}

textarea {
  resize: none;
}

input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--ocean-blue);
}

button {
  font-size: $font-text;

  &:focus {
    outline: none;
  }
}

.grecaptcha-badge {
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.overflow-hidden {
  overflow: hidden;
}
