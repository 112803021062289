@import '@next-insurance/ni-material/styles/mixins.scss';

.mt-section {
  margin-top: var(--ni-2xl-spacing);

  @include on-desktop {
    margin-top: var(--ni-3xl-spacing);
  }
}

.mt-card {
  margin-top: var(--ni-lg-spacing);

  @include on-desktop {
    margin-top: var(--ni-xl-spacing);
  }
}

.mt-sm-card {
  margin-top: var(--ni-sm-spacing);

  @include on-desktop {
    margin-top: var(--ni-lg-spacing);
  }
}
