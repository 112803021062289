// border-radius
$border-radius: 4px;

// Navigation
$nav-height-mobile: 57px;
$nav-height-desktop: 100px;

// Footer
$footer-height: 76px;

// Font Size
$font-size-extra-big: 48px;
$font-size-big: 40px;
$font-size-36: 36px;
$font-size-34: 34px;
$font-sub-header: 32px;
$font-size-28: 28px;
$font-size-24: 24px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-22: 22px;
$font-text: 16px;
$font-small-text: 14px;
$font-size-12: 12px;
$font-tiny-text: 10px;

/*** Font Weights ***/
$thin-font-weight: 300;
$normal-font-weight: 400;
$medium-font-weight: 500;
$semi-bold-font-weight: 600;
$bold-font-weight: 700;

/*** Font Family ***/
$font-family-sans: 'OpenSans';

// Color Palette
// examples based on the official Sass styleguide
// descriptive colors
// =============
$blue-primary: #03c8ff;
$blue-secondary: #00a0d3;
$blue-light: #345672;
$blue-extra-light: #f0fbff;
$blue-ink: #0e2b42;
$blue-ink-30-perc: rgb(14 43 66 / 30%);
$blue-ink-50-perc: rgb(14 43 66 / 50%);
$blue-ink-70-perc: rgb(14 43 66 / 70%);
$blue-ink-80-perc: rgb(14 43 66 / 80%);
$blue-ink-95-perc: rgb(14 43 66 / 95%);
$black-17-perc: rgb(0 0 0 / 17%);
$light-blue: rgb(3 200 255 / 10%);
$white-light-blue: #f0f9fc;
$blue-green-light: #dcecf2;
$blue-gray-light: #ecf6f9;
$blue-darkest: #071521;
$dark-gray: #0e2b42cc;
$blue-mid: #158fc1;
$blue-default-darker: #0fa3e0;
$blue-default: #00a3e0;
$lighter-blue: #a8e7fe;
$yellow: #fff100;
$yellow-dark: #ffdf59;
$yellow-dark-40-perc: rgb(255 223 89 / 40%);
$green-primary: #00e650;
$green-secondary: #00e5b7;
$green-tertiary: #00e4b7;
$green-dark: #28b145;
$green-light: #e7f7eb;
$gray-blue-translucent: rgb(206 213 218 / 50%);
$gray-blue: #8094a6;
$gray-light: #f0f2f3;
$gray-light-extra: #f7f7f7;
$gray-light-medium: #f7f8f9;
$gray-light-shade: #ced5da;
$gray-lighter-shade: #e7e7e7;
$gray-strong: #c3cbd2;
$grey-regular: #999;
$gray-dark: #5d6268;
$gray-darkest: #46494d;
$grey-black: #231f20;
$gray-background: #fafbfc;
$gray-inactive: #747474;
$orange: #ff7422;
$warning-red: rgb(227 59 56 / 10%);
$red: #e33b38;
$dodgerblue: $blue-primary;
$robinsegg: $green-secondary;
$robinsegg2: $green-secondary;
$mountainmeadow: $green-secondary;
$junglegreen: #24a087;
$rollingstone: #767d85;
$abbey: #535961;
$abbey2: #4d5156;
$abbey3: #46494d;
$shuttlegrey: #5d6268;
$shuttlegrey2: #61666e;
$shuttlegrey3: #61666d;
$dove-gray: #666;
$alto: $gray-light-shade;
$alto2: $gray-light-shade;
$gallery: $gray-light;
$wildsand: $gray-light;
$alabaster: $gray-light;
$nevada: #696f78;
$greychateau: #919baa;
$caribbeangreen: $green-primary;
$caribbeangreen2: $green-primary;
$caribbeangreen3: $green-primary;
$curiousblue: #1caad6;
$hopbush: #c69;
$bouquet: #b37399;
$venus: #998099;
$patina: #699;
$nebula: #d2e1dd;
$white: #fff;
$white-smoke: #f5f5f5;
$dawn-pink: #f2ece4;
$wafer: #e1d7d2;
$iron: #dadbdf;
$regent-grey: $gray-blue;
$regent-grey2: $gray-blue;
$pale-sky: $gray-blue;
$midnight-blue: #036;
$black: #000;
$concrete: $gray-light-shade;
$silver: #ccc;

// main color palette
// =============
$color-primary: $blue-primary;
$color-secondary: $bouquet;
$color-accent: $patina;
$color-shadow: rgba($black, 0.125);
$color-note: #666;

// Common colors
$color-background: $white;
$color-background-shade: $iron;
$color-background-invert: $shuttlegrey;
$color-text: $black;
$color-text-weak: $regent-grey;
$color-text-strong: $midnight-blue;
$color-text-heading: $regent-grey;
$color-text-invert: rgba($color-background, 0.75);
$color-text-strong-invert: $color-background;

// Links
$color-link: $color-primary;
$color-link-hover: $midnight-blue;
$color-link-visited: $bouquet;

// Code
$color-code: #333;
$color-code-background: #f3f3f3;
$color-pre: #d4d4d4;
$color-pre-background: #333;

// Selections
$color-selection: #b3d4fc;

// Messages
$color-message: #f4ecbb;
$color-success: #2cde2c;
$color-warning: #cf8600;
$color-important: #d00;
$color-error: #d00;
$color-notice: #66b;

// for portal
$gainsboro: #e2e2e2;
$gray74: #bdbdbd;
$link: var(--ocean-blue);
$ni-blue: #4ad8ff;
$medium-blue: #02afdf;
$font-size-regular: 17px;
$small-font-size: 15px;
$very-small-font-size: 8px;

// Background color
$light-gray: #ddd;
$very-light-gray-blue: rgb(236 246 249 / 50%);

// border
$header-border: 1px solid #bbb;
$border-solid: 1px solid lightgrey;
$border-solid-2x: 2px solid lightgrey;
$border-solid-black: 1px solid black;
$border-solid-gray-strong: 1px solid $gray-strong;
$border-dotted-gray: 2px dotted $gray-lighter-shade;

// shadow
$box-shadow: rgba(0, 0, 0, 16%);

// badge
$red-text: #c63737;
$red-background: #ffcdd2;
$grey-text: $regent-grey;
$grey-background: #ededed;
$brown-text: #805b36;
$brown-background: #ffd8b2;
$green-text: #256029;
$green-background: #c8e6c9;
$blue-text: #23547b;
$blue-background: #b3e5fc;
$purple-text: #694382;
$purple-background: #eccfff;

// Line Height
$normal-line-height: 1.47;
