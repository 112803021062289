@import 'driver.js/dist/driver.css';

.driver-popover {
  .driver-popover-description {
    font-family: Graphik, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    padding: 20px 0 16px 0;
    text-align: start;
    margin-top: -24px;
    overflow-wrap: break-word;
  }

  .driver-popover-navigation-btns {
    .driver-popover-next-btn {
      margin-right: auto;
      cursor: pointer;
      color: var(--ocean-blue);
      font-family: Graphik, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      background: none;
      border: none;
      padding: 0;
    }
  }
}
